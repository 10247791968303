import { default as _91_46_46_46page_9321sIfNCgmMMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/[...page].vue?macro=true";
import { default as change_45passwordsjktLqLIrQMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/account/change-password.vue?macro=true";
import { default as indexWkkc9g3qNOMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/account/index.vue?macro=true";
import { default as _91uuid_93CP7IkhrbF4Meta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/afspraak/[uuid].vue?macro=true";
import { default as _91token_93KRQyarglKwMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/activate/[token].vue?macro=true";
import { default as login86LyM77EpAMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/login.vue?macro=true";
import { default as request_45passwordObdA6GCbPmMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/request-password.vue?macro=true";
import { default as _91token_93RSrl4vJdfyMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/reset-password/[token].vue?macro=true";
import { default as bewerkenoPtPV59AQ5Meta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/deelnemers/[uuid]/bewerken.vue?macro=true";
import { default as index1fdZZSjfPxMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/deelnemers/[uuid]/index.vue?macro=true";
import { default as indexvaeASxfoaFMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/deelnemers/index.vue?macro=true";
import { default as _91id_93S4733ROB8SMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/forms/[id].vue?macro=true";
import { default as index6bvE8ZiTbvMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/index.vue?macro=true";
import { default as bewerkenxdj1bL6vvZMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/bewerken.vue?macro=true";
import { default as index5B6cbTwMYMMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/index.vue?macro=true";
import { default as indexmgJTGvQZjhMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/training/[name]/index.vue?macro=true";
import { default as nieuwRmKp1T8L0rMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/training/nieuw.vue?macro=true";
import { default as indexjIqGEr77zVMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/index.vue?macro=true";
import { default as nieuw5eFZl1kn7RMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/nieuw.vue?macro=true";
import { default as _91slug_93yOkTCMmW5qMeta } from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/training/[slug].vue?macro=true";
export default [
  {
    name: "page",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_9321sIfNCgmMMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "account-change-password",
    path: "/account/change-password",
    meta: change_45passwordsjktLqLIrQMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexWkkc9g3qNOMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "afspraak-uuid",
    path: "/afspraak/:uuid()",
    meta: _91uuid_93CP7IkhrbF4Meta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/afspraak/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-activate-token",
    path: "/auth/activate/:token()",
    meta: _91token_93KRQyarglKwMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/activate/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login86LyM77EpAMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-request-password",
    path: "/auth/request-password",
    meta: request_45passwordObdA6GCbPmMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token()",
    meta: _91token_93RSrl4vJdfyMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "deelnemers-uuid-bewerken",
    path: "/deelnemers/:uuid()/bewerken",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/deelnemers/[uuid]/bewerken.vue").then(m => m.default || m)
  },
  {
    name: "deelnemers-uuid",
    path: "/deelnemers/:uuid()",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/deelnemers/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: "deelnemers",
    path: "/deelnemers",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/deelnemers/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-id",
    path: "/forms/:id()",
    meta: _91id_93S4733ROB8SMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/forms/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index6bvE8ZiTbvMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "programmas-slug-bewerken",
    path: "/programmas/:slug()/bewerken",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/bewerken.vue").then(m => m.default || m)
  },
  {
    name: "programmas-slug",
    path: "/programmas/:slug()",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "programmas-slug-training-name",
    path: "/programmas/:slug()/training/:name()",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/training/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: "programmas-slug-training-nieuw",
    path: "/programmas/:slug()/training/nieuw",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/[slug]/training/nieuw.vue").then(m => m.default || m)
  },
  {
    name: "programmas",
    path: "/programmas",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/index.vue").then(m => m.default || m)
  },
  {
    name: "programmas-nieuw",
    path: "/programmas/nieuw",
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/programmas/nieuw.vue").then(m => m.default || m)
  },
  {
    name: "training-slug",
    path: "/training/:slug()",
    meta: _91slug_93yOkTCMmW5qMeta || {},
    component: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/pages/training/[slug].vue").then(m => m.default || m)
  }
]